import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { PlatformService } from './plateform.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(private platformService: PlatformService) { }

  public isAGoogleRequest(request: HttpRequest<unknown>) {
    let requestOutput = null;
    if(request.url.includes('google')){
      requestOutput = request;
    }
   return requestOutput
  }

  public isAi18nRequest(request: HttpRequest<unknown>) {
    let requestOutput = null;
    if(request.url.includes('/assets/i18n')){
      requestOutput = request.clone({
        headers: request.headers.set('Cache-Control', 'no-store, no-cache'),
    });
    }
   return requestOutput
  }

  public handleDefaultRequests(request: HttpRequest<unknown>): HttpRequest<unknown>{
    let baseUrl = this.platformService.isPlatformBrowser() ?  environment.baseUrl :  environment.baseUrlSsr;
    const rewriteUrl = request.clone({
        url: baseUrl + '/api/' + request.url,
    });
    return rewriteUrl
  }
}
