import {NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {environment} from 'src/environments/environment';
import {colors} from 'src/assets/scss/custom/colors';

export const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.seoUrl // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: colors.primaryDark
    },
    button: {
      background: colors.secondary
    },
  },
  theme: 'edgeless',
  type: 'opt-out'
};