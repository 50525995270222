import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateCacheModule, TranslateCacheService, TranslateCacheSettings } from 'ngx-translate-cache';
import { Observable, of } from 'rxjs';

import { PlatformService } from '../services/plateform.service';
import { CookieService } from '../services/cookie.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient, PlatformService]
      }
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: translateCachedFactory,
        deps: [TranslateService, TranslateCacheSettings]
      },
      cacheMechanism: 'Cookie'
    })
  ],
  exports: [TranslateModule]
})

export class I18nModule {
  defaultLang = 'fr';
  constructor(
    translate: TranslateService,
    translateCacheService: TranslateCacheService,
    private platformService: PlatformService,
    private cookieService: CookieService) {

    translate.addLangs(['en', 'fr']);

    // Determine if the platform is browser or server-side
    let lang = this.platformService.isPlatformBrowser()
    ? this.getLangForBrowserSide(translateCacheService, translate)
    : this.getLangForServerSide(translate);

    translate.use(lang);
  }

  getLangForBrowserSide(translateCacheService: TranslateCacheService, translate: TranslateService): string {
    translateCacheService.init();
    let lang = translateCacheService.getCachedLanguage() || translate.getBrowserLang() || this.defaultLang;
    //console.warn("browser lang : ", lang);
    return lang
  }

  getLangForServerSide(translate: TranslateService) {
    let lang = this.cookieService.getSpecificKeyFromCookie('lang') || this.defaultLang;
    //console.warn("server lang : ", lang);
    return lang;
  }
}

export function TranslateLoaderFactory(httpClient: HttpClient, platformService: PlatformService): any {  
  return platformService.isPlatformBrowser()
    ? new TranslateHttpLoader(httpClient)
    : new TranslateFSLoader();
}

export function translateCachedFactory(
    translateService: TranslateService,
    translateCacheSettings: TranslateCacheSettings
  ): any {
  return new TranslateCacheService(translateService, translateCacheSettings);
}

export class TranslateFSLoader implements TranslateLoader {
  constructor(private prefix = './assets/i18n', private suffix = '.json') { }

  public getTranslation(lang: string): Observable<any> {    
    let fs = require('fs');
    
    let i18nPath_1 = this.getI18nPath('dist/pirog/browser', lang); // for live ssr requests
    let i18nPath_2 = this.getI18nPath('src', lang); // for prerendering generation
    let i18nPath = fs.existsSync(i18nPath_1) ? i18nPath_1 : i18nPath_2;
    
    const data = JSON.parse(fs.readFileSync(i18nPath, 'utf8'));
    return of(data);
  }

  private getI18nPath(parentPath:string, lang: string): string{
    let pathLibrary = require('path');
    return pathLibrary.join(parentPath, this.prefix, `${lang.toLowerCase()}${this.suffix}`);
  }
}