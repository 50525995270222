import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';

import {AuthGuard} from './guards/auth.guard';
import {AppLoadingComponent} from './components/shared/app-loading/app-loading.component';
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {PublicComponent} from "./components/public/public.component";
import {NotFoundComponent} from "./components/public/pages/not-found/not-found.component";

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/public/public.module')
            .then((m) => {
              return m.PublicModule
            }),
      },
    ],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/dashboard/dashboard.module')
            .then((m) => {
              return m.DashboardModule
            }),
      },
    ],
  },
  {
    path: 'app-loading/:applicationId',
    component: AppLoadingComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollOffset: [0, 0],
      scrollPositionRestoration: 'top'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
