import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromAuth from './auth/auth.reducer';
import * as fromLoader from './loader/loader.reducer';
import * as fromReposiotry from './repository/repository.reducer';
import * as fromOrder from './order/order.reducer';

export interface AppState {
    [fromAuth.authFeatureKey]: fromAuth.AuthState;
    [fromLoader.loaderFeatureKey]: fromLoader.LoaderState;
    [fromReposiotry.repositoryFeatureKey]: fromReposiotry.RepositoryState;
    [fromOrder.orderFeatureKey]: fromOrder.OrderState;
}

export const reducers: ActionReducerMap<AppState> = {
    [fromAuth.authFeatureKey]: fromAuth.reducer,
    [fromLoader.loaderFeatureKey]: fromLoader.reducer,
    [fromReposiotry.repositoryFeatureKey]: fromReposiotry.reducer,
    [fromOrder.orderFeatureKey]: fromOrder.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
