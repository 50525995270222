import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { logout } from '../store/auth/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../services/toaster.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private store: Store,
        private translateService: TranslateService,
        private toasterServiceCsr: ToastService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler) {
        return next.handle(request).pipe(
            catchError((error) => {
                console.log('errorIntercept error --> ', error);
                console.log('errorIntercept request --> ', request);
                if (error) {
                    if (error.status === 400) {
                        this.toasterServiceCsr.info(this.translateService.instant(error?.error?.error), this.translateService.instant("error"));}
                    if (error.status === 403) {
                        this.store.dispatch(logout());
                    }
                    if (error.status === 404) {
                        this.router.navigateByUrl('/not-found');
                    }
                    if (error.status === 401 || error.status === 409) {
                        const errorMessage = this.translateService.instant(error.error.message);
                        this.toasterServiceCsr.warning(errorMessage, this.translateService.instant("error"));
                    }
                    if (error.status === 500) {
                        const navigationExtras: NavigationExtras = { state: { error: error.error } };
                        this.router.navigateByUrl('/server-error', navigationExtras);
                        this.toasterServiceCsr.warning(error?.error?.error , this.translateService.instant("server_error"));
                    }
                }
                return throwError(error);
            })
        );
    }
}
