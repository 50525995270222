import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private httpClient: HttpClient, private router: Router) {}

    login(email: string, password: string, token: string,language:string): Observable<any> {
        return this.httpClient.post<User>('auth/login', { email, password, token, language }, { observe: 'response' });
    }

    register(user: User): Observable<any> {
        return this.httpClient.post<any>('auth/register', user);
    }
}
