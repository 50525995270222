import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of } from 'rxjs';
import {
    loadSelectedApplication,
    loadSelectedApplicationFailure,
    loadSelectedApplicationIsConfigComplete,
    loadSelectedApplicationIsConfigCompleteFailure,
    loadSelectedApplicationIsConfigCompleteSuccess,
    loadSelectedApplicationSuccess,
} from './application-repository.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TemplateService } from 'src/app/services/template.service';

@Injectable()
export class ApplicationRepositoryEffects {
    constructor(private actions$: Actions, private applicationService: ApplicationService, private templateService: TemplateService) {}

    loadSelectedApplication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSelectedApplication),
            mergeMap((props) =>
                this.applicationService.getApplicationById(props.idApplication).pipe(
                    mergeMap((application) => [loadSelectedApplicationSuccess({ application })]),
                    catchError(() => of(loadSelectedApplicationFailure()))
                )
            )
        )
    );

    loadSelectedApplicationIsConfigComplete$ = createEffect(() =>
    this.actions$.pipe(
        ofType(loadSelectedApplicationIsConfigComplete),
        mergeMap((props) =>
            this.templateService.isApplicationConfigComplete(props.idApplication).pipe(
                mergeMap((response: {complete:boolean}) => [loadSelectedApplicationIsConfigCompleteSuccess({ isConfigComplete: response.complete })]),
                catchError(() => of(loadSelectedApplicationIsConfigCompleteFailure()))
            )
        )
    )
);
}
