import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, tap } from 'rxjs';
import { TemplateService } from 'src/app/services/template.service';
import { loadTemplates, loadTemplatesSuccess } from './template.actions';

@Injectable()
export class TemplateEffects {
    constructor(
        private actions$: Actions,
        private templateService: TemplateService
    ) {}

    loadTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadTemplates),
            mergeMap(() =>
                this.templateService.getAllTemplatesOnSale().pipe(map((templates) => loadTemplatesSuccess({ templates })))
            )
        )
    );
}
