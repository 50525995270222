import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap, withLatestFrom } from 'rxjs';

import { OrderState } from './order.reducer';
import { Store } from '@ngrx/store';
import { OrderLocalStorageService } from 'src/app/services/order-local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { clearCart, clearCartSuccess, clearCreationOrder, clearCreationOrderSuccess, loadApplicationStep, loadApplicationStepSuccess, loadDomainStep, loadDomainStepSuccess, loadOptionsStep, loadOptionsStepSuccess, loadTemplateType, loadTemplateTypeSuccess, setApplicationStep, setApplicationStepSuccess, setCreationOrder, setCreationOrderSuccess, setDomainStep, setDomainStepSuccess, setOptionsStep, setOptionsStepSuccess, setStep, setStepSuccess, setTemplateType, setTemplateTypeSuccess } from './order.actions';

@Injectable()
export class OrderEffects {
    constructor(
        private actions$: Actions,
        private orderLocalStorageService: OrderLocalStorageService,
    ) { }

    setApplicationStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setApplicationStep),
            mergeMap((props) => {
                this.orderLocalStorageService.setApplicationStep(props.applicationStep);
                return of(
                    setApplicationStepSuccess({
                        applicationStep: props.applicationStep,
                    })
                );
            })
        )
    );

    loadApplicationStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadApplicationStep),
            mergeMap(() => {
                return of(
                    loadApplicationStepSuccess({ applicationStep: this.orderLocalStorageService.getApplicationStep() })
                );
            })
        )
    );

    setDomainStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setDomainStep),
            mergeMap((props) => {
                this.orderLocalStorageService.setDomainStep(props.domainStep);
                return of(
                    setDomainStepSuccess({
                        domainStep: props.domainStep,
                    })
                );
            })
        )
    );

    loadDomainStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDomainStep),
            mergeMap(() => {
                return of(
                    loadDomainStepSuccess({ domainStep: this.orderLocalStorageService.getDomainStep() })
                );
            })
        )
    );

    setOptionsStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setOptionsStep),
            mergeMap((props) => {
                this.orderLocalStorageService.setOptionsStep(props.optionsStep);
                return of(
                    setOptionsStepSuccess({
                        optionsStep: props.optionsStep,
                    })
                );
            })
        )
    );

    loadOptionsStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadOptionsStep),
            mergeMap(() => {
                return of(
                    loadOptionsStepSuccess({ optionsStep: this.orderLocalStorageService.getOptionsStep() })
                );
            })
        )
    );

    setStepEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setStep),
            mergeMap((props: any) => {
                this.orderLocalStorageService.setStep(props.step);
                return of(setStepSuccess({ step: this.orderLocalStorageService.getStep() }));
            })
        )
    );

    setTemplateType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setTemplateType),
            mergeMap((props) => {
                this.orderLocalStorageService.setTemplateType(props.templateType);
                return of(setTemplateTypeSuccess({ templateType: this.orderLocalStorageService.getTemplateType() }));
            })
        )
    );

    loadTemplateType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadTemplateType),
            mergeMap(() => {
                return of(
                    loadTemplateTypeSuccess({ templateType: this.orderLocalStorageService.getTemplateType() })
                );
            })
        )
    );

    clearReservation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clearCart),
            mergeMap(() => {
                this.orderLocalStorageService.clearCart();
                return of(clearCartSuccess());
            })
        )
    );

    setCreationOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setCreationOrder),
            mergeMap((props) => {
                this.orderLocalStorageService.setCreationOrder(props.creationOrder);
                return of(setCreationOrderSuccess({ creationOrder: props.creationOrder }));
            })
        )
    );

    clearCreationOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clearCreationOrder),
            mergeMap(() => {
                this.orderLocalStorageService.clearCreationOrder();
                return of(clearCreationOrderSuccess());
            })
        )
    );
}
