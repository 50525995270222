import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    loadAuth,
    loadAuthSuccess,
    loadUserById,
    loadUserByIdFailure,
    loadUserByIdSuccess,
    logout,
    resetPasswordAttempt,
    resetPasswordAttemptFailure,
    resetPasswordAttemptSuccess,
    resetPasswordProcess,
    resetPasswordProcessFailure,
    resetPasswordProcessSuccess,
    signIn,
    signInFailure,
    signInSuccess,
    signUp,
    signUpFailure,
    signUpSuccess,
    updateUser,
    updateUserFailure,
    updateUserSuccess,
    verifyAccount,
    verifyAccountAttempt,
    verifyAccountAttemptFailure,
    verifyAccountAttemptSuccess,
    verifyAccountFailure,
    verifyAccountSuccess
} from './auth.actions';
import { AuthService } from '../../services/auth.service';
import { catchError, filter, map, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { LocalStorageService } from '../../services/local-storage.service';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { selectUserId } from './auth.selectors';
import { selectCreationOrder } from '../order/order.selectors';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'src/app/services/application.service';
import { ApplicationCreationResponse } from 'src/app/models/application-creation-response.model';
import { clearCreationOrder, setTemplateType } from '../order/order.actions';
import { ApplicationCreationRequest } from 'src/app/models/application-creation-request.model';
import { AppCreationOrder } from '../order/models/creation-order.model';
import { PlatformService } from 'src/app/services/plateform.service';
import { LanguageService } from 'src/app/services/language.service';
import { CookieService } from 'src/app/services/cookie.service';
import { ToastService } from 'src/app/services/toaster.service';
import { Application } from 'src/app/models/application.model';
import { TrackingApplicationCreation } from 'src/app/models/tracking.model';
import { TrackingService } from 'src/app/services/tracking.service';

@Injectable()
export class AuthEffects {
    constructor(
        private store: Store,
        private router: Router,
        private actions$: Actions,
        private userService: UserService,
        private authService: AuthService,
        private toastService: ToastService,
        private cookieService: CookieService,
        private languageService: LanguageService,
        private trackingService: TrackingService,
        private plateformService: PlatformService,
        private translateService: TranslateService,
        private applicationService: ApplicationService,
        private localStorageService: LocalStorageService
    ) { }

    loadAuth$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(loadAuth),
          mergeMap(() => {
              return of(this.cookieService.getUser()).pipe(
                map((user) => {
                  return loadAuthSuccess({
                    token: user?.token,
                    userId: user?.userId,
                    role: user?.role
                  });
                })
              )
            }
          )
        )
      }
    );

    signInEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signIn),
            mergeMap((props: any) => {
                let language = this.languageService.getLanguage();
                return this.authService.login(props.email, props.password, props.token,language).pipe(
                    map((resp: any) => {
                        const token = resp.headers.get('Authorization').slice(7);
                        this.cookieService.setAccessToken(token);
                        if(this.plateformService.isPlatformBrowser()){
                            const role = this.localStorageService.getRole();
                            const userId = this.localStorageService.getSubject();
                            return signInSuccess({ bearer: token, role, userId });
                        }
                    }),
                    catchError((err: any) => {
                        return of(signInFailure({ error: { status: err.status, url: err.url, message: err.message } }));
                    })
                );
            })
        )
    );

    signInSuccessEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(signInSuccess),
                withLatestFrom(this.store.select(selectCreationOrder)),
                tap((props) => {
                    const userId = props[0].userId;
                    const creationOrder = props[1];
                    if (creationOrder == null) {
                        this.router.navigate(['/dashboard', 'applications']);
                    } else {
                        this.launchApplicationCreation(creationOrder, userId)
                    }
                })
            ),
        { dispatch: false }
    );

    signUpEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signUp),
            mergeMap((props: any) => {
                return this.authService.register(props.user).pipe(
                    mergeMap(() => [
                        signUpSuccess(),
                        signIn({ email: props.user.email, password: props.user.password, token: '' }),
                    ]),
                    catchError((err: any) =>
                        of(signUpFailure({ error: { status: err.status, url: err.url, message: err.message } }))
                    )
                );
            })
        )
    );

    logoutEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(logout),
                tap(() => {
                    this.cookieService.deleteAccessToken();
                    this.router.navigate(['/login']);
                })
            ),
        { dispatch: false }
    );

    loadUserById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserById),
      withLatestFrom(this.store.select(selectUserId)),
      filter(([action, userId]) => userId !== null && userId !== undefined),
      mergeMap(([action, userId]) => {
        return this.userService.getUserById(userId).pipe(
          map(user => {
            return loadUserByIdSuccess({user})
          }),
          catchError(error => {
            return of(loadUserByIdFailure({error}))
          })
        )
      })
    )
  );

    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUser),
            mergeMap((props) =>
                this.userService.updateUser(props.user).pipe(
                    map((user) => {
                        return updateUserSuccess({
                            user,
                        });
                    }),
                    catchError((err) =>
                        of(
                            updateUserFailure({
                                error: { status: err.status, url: err.url, message: err.error.message },
                            })
                        )
                    )
                )
            )
        )
    );

    updateUserSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUserSuccess),
            mergeMap((props) => of(loadUserById()))
        )
    );

    verifyAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(verifyAccount),
            mergeMap((props) =>
                this.userService.verifyEmailWithMailClic(props.token).pipe(
                    map((user) => {
                        return verifyAccountSuccess();
                    }),
                    catchError((err) =>
                        of(
                            verifyAccountFailure({
                                error: { status: err.status, url: err.url, message: err.error.message },
                            })
                        )
                    )
                )
            )
        )
    );

    verificationAccountAttempt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(verifyAccountAttempt),
            mergeMap((props) =>
                this.userService.verifyEmailWithMailByAccountId(props.idUser).pipe(
                    map((user) => {
                        const message = this.translateService.instant("login.popup");
                        this.toastService.success(message);
                        return verifyAccountAttemptSuccess();
                    }),
                    catchError((err) =>
                        of(
                            verifyAccountAttemptFailure({
                                error: { status: err.status, url: err.url, message: err.error.message },
                            })
                        )
                    )
                )
            )
        )
    );

    resetPasswordAttempt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(resetPasswordAttempt),
            mergeMap((props) =>
                this.userService.resetPasswordAttempt(props.resetPasswordAttemptRequest).pipe(
                    map(() => {
                        return resetPasswordAttemptSuccess();
                    }),
                    catchError((err) =>

                        of(
                            resetPasswordAttemptFailure({
                                error: { status: err.status, url: err.url },
                            })
                        )
                    )
                )
            )
        )
    );

    ResetPasswordAttemptFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(resetPasswordAttemptFailure),
                tap(() => {
                    const message = this.translateService.instant("reset_pass.email_received_google");
                    this.toastService.success(message);
                    return;
                })
            ),
        { dispatch: false }
    );

    ResetPasswordAttemptSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(resetPasswordAttemptSuccess),
                tap(() => {
                    const message = this.translateService.instant("reset_pass.email_received");
                    this.toastService.success(message);
                })
            ),
        { dispatch: false }
    );

    resetPasswordProcess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(resetPasswordProcess),
            mergeMap((props) =>
                this.userService.resetPasswordProcess(props.resetPasswordProcessRequest).pipe(
                    map(() => {
                        return resetPasswordProcessSuccess();
                    }),
                    catchError((err) =>
                        of(
                            resetPasswordProcessFailure({
                                error: { status: err.status, url: err.url, message: err.error.message },
                            })
                        )
                    )
                )
            )
        )
    );

    ResetPasswordProcessSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(resetPasswordProcessSuccess),
                tap(() => {
                    const message = this.translateService.instant("login.pass");

                    this.toastService.success(message);
                    this.router.navigate(['/login']);
                })
            ),
        { dispatch: false }
    );

    private launchApplicationCreation(creationOrder: AppCreationOrder, userId: string) {
        const request: ApplicationCreationRequest = {
            appName: creationOrder.appName,
            idTemplate: creationOrder.idTemplate,
            subDomainName: creationOrder.subDomainName,
            domainType: creationOrder.domainType
          }

        this.applicationService.createApplication(request)
            .subscribe({
                next: (uploadResult) => {
                    this.handleSuccess(uploadResult);
                },
                error: (err: any) => {
                    this.handleError(err, userId)
                }
            });
    }

    private handleSuccess(uploadResult: ApplicationCreationResponse) {
        this.store.dispatch(setTemplateType({ templateType: null }));
        this.store.dispatch(clearCreationOrder());

        this.trackApplicationCreation(uploadResult.application);

        if (uploadResult.redirectToWaitingRoom) {
          this.router.navigate(['/app-loading', uploadResult.application.id]);
        } else {
          this.router.navigate(['/dashboard', 'applications', uploadResult.application.id, 'overview']);
        }
      }

      private handleError(error: any, userId: string) {
        if(error?.error?.error === "user_not_verified") {
            this.store.dispatch(verifyAccountAttempt({ idUser: userId }));
            this.router.navigate(['/']);
        }

        if (error.error.error === "domain_name_unavailable") {
            this.router.navigate(['/']);
        }
      }

      private trackApplicationCreation(application: Application) {
        let trackingDTO: TrackingApplicationCreation = {
          eventType: 'applicationCreated',
          pageUrl: this.router.url,
          templateId: application.template.id,
          templateName: application.template.name,
          applicationId: application.id,
          domainName: application.domain.name,
          subscriptionId: application.subscriptionId,
          subscriptionType: 'todo'
        }
        this.trackingService.track(trackingDTO);
      }
}
