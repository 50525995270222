import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ToastContainerModule, ToastrModule} from 'ngx-toastr';
import {TagInputModule} from 'ngx-chips';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {HIGHLIGHT_OPTIONS, HighlightModule} from 'ngx-highlightjs';
import {NgxFileDropModule} from 'ngx-file-drop';
import {SsrCookieService} from 'ngx-cookie-service-ssr';
import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {BaseUrlInterceptor} from './interceptors/base-url.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {JwtInterceptor} from './interceptors/jwt.interceptor';
import {LoaderInterceptor} from './interceptors/loader.interceptor';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from 'src/environments/environment';
import {metaReducers, reducers} from './store';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './store/auth/auth.effects';
import {ApplicationRepositoryEffects} from './store/repository/application/application-repository-effects.service';
import {OrderEffects} from './store/order/order.effects';
import {TemplateEffects} from './store/repository/template/template.effects';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {I18nModule} from './i18n/i18n.module';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {CommonModule} from '@angular/common';
import {DashboardModule} from "./components/dashboard/dashboard.module";
import {PublicModule} from "./components/public/public.module";
import {SharedModule} from "./components/shared/shared.module";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import { cookieConfig } from './cookie-consent.config';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 100000,
      closeButton: false
    }),
    ToastContainerModule,
    CollapseModule.forRoot(),
    DragDropModule,
    NgxFileDropModule,
    TagInputModule,
    BrowserAnimationsModule,
    FormsModule,
    I18nModule,
    PublicModule,
    SharedModule,
    DashboardModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([
      AuthEffects,
      ApplicationRepositoryEffects,
      TemplateEffects,
      OrderEffects,
    ]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    HighlightModule,
    PaginationModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    // GoogleTagManagerModule.forRoot({
    //   id: environment.GTM_ID,
    //   gtm_auth:  environment.GTM_AUTH,
    //   gtm_preview: environment.GTM_ENV
    // })
  ],
  providers: [
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: 'googleTagManagerId', useValue: environment.GTM_ID },
    // { provide: 'googleTagManagerAuth', useValue: environment.GTM_AUTH },
    // { provide: 'googleTagManagerPreview', useValue: environment.GTM_ENV },
    // { provide: 'googleTagManagerResourcePath', useValue: 'https://www.googletagmanager.com/gtm.js' },
    // { provide: 'googleTagManagerMode', useValue: 'noisy'},
    // { provide: 'googleTagManagerCSPNonce',useValue:'nonce'},
    GoogleTagManagerService,
    SsrCookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('highlightjs-line-numbers.js'), // Optional
        languages: {
          python: () => import('highlight.js/lib/languages/python')
        },
        themePath: 'assets/scss/highlight-js/github-dark.css'
      }
    }
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
}
